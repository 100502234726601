import React, { Component } from 'react';
import '../App.css';
import axios from 'axios';
import jwtDecode from 'jwt-decode';
import { Link, useParams } from 'react-router-dom';
import Headertop from './Headerprotop';

import Header from './Header';

class ViewProfessionalProfile extends Component {
  constructor(props) {
    super(props);
    this.state = {
      reqqs: [],
      lang:'en'
    };
  }

  componentDidMount() {
    var lan = localStorage.getItem("lang");
    //alert(lan);
    if(lan=='fr')
    {
      this.setState({lang: 'fr'});
    }
    else
    {
      this.setState({lang: 'en'});
    }
    const token = localStorage.getItem("professional-token");
    axios.get('/api/professionals/get-professional/'+token).then(res => {
      this.setState({
        prof: res.data
       });
       
    })

    .catch(err =>{
      console.log('Error from professional');
    })
    const p_status = localStorage.getItem("p_status");
    if(!token)
    {
      window.location.href = "/login-as-professional";
    }
    
    const u = jwtDecode(token);
    console.log(u.id);
    
    axios
      .get('/api/professionals/view-profile/'+u.id,
      {
       headers: { 'ngrok-skip-browser-warning':'5'}
      }
      )
      .then(res => {
        this.setState({
          reqqs: res.data
         })
         
      })
      .catch(err =>{
        console.log('Error from Profile');
      })
      
  };
  

  render() {
    var lang=this.state.lang;
    const reqqs = this.state.reqqs;
    //console.log("PrintBook: " + JSON.stringify(reqqs));
    var photo, licence, licence1;
    if(reqqs.photo_id=='' || reqqs.photo_id==undefined)
    {
photo = 'avatar-3637425_640.jpg';
    }
    else
    {
      photo = reqqs.photo_id;
    }

    if(reqqs.licence!=undefined)
    {
     var temp = reqqs.licence;
     licence = temp.textValues;
     licence1 = temp.fileValues;
    }
    else
    {
      licence ='';
      licence1 = '';
    }

    return (
      <div>
<Headertop/>
      <div className="container-fluid">
      <div className='row'>
        <Header />
        <div className='col-md-10 menu-right'>
      
        <div className="dashboard-right ">
    <div className="dashboard-cart">
        <h2 className="page-title lang">Profile</h2>
        <div className='reset-password1'>
              <a className='btn btn-primary' href='/update-professional-profile'>Edit</a></div>
        <div className="row">
  <div className="col-md-8">
    <h4><img src={"/uploads/"+photo} width="80"/>{reqqs.name}</h4>
    <h4 className="lang">About me</h4>
    <p className="lang"><strong >Apporved: </strong>{reqqs.status=='1'?'Yes':'No'}</p>
    <p className="lang">{reqqs.description}</p>
    <h4 className="lang">Professional Information</h4>
    <p><strong className="lang">Specialisation: </strong> <span className="lang">{reqqs.specialisation}</span></p>
    
    <p><strong className="lang">Experience:</strong> <span className="lang">{reqqs.experience} years</span></p>
    <p><strong className="lang">Minimum Fees: </strong> <span className="lang">${reqqs.standard_fees}</span></p>
    
    </div>
    <div className="col-md-4 profile_right">
    <h4 className="lang">Basic Information</h4>
    <p><strong className="lang">Email:</strong> <span className="lang">{reqqs.email}</span></p>
      <p><strong className="lang">Office Address:</strong><span className="lang">{reqqs.office_address}</span></p>
      <p><strong className="lang">Radius to Cater:</strong><span className="lang">{reqqs.radius_to_cater}</span></p>
      <p><strong className="lang">Registered Address:</strong><span className="lang">{reqqs.registered_address}</span></p>
      <p><strong className="lang">Qualification: </strong><span className="lang">{reqqs.qualification}</span></p>
      
      </div>
      <div className="col-md-12"><br/><br/>
      <h4 className="lang">Licence Information</h4>
      
      <table className="table table-striped"> 
      {(() => {
          const options = [];

          for (let i = 0; i <= 10; i++) {
            if(licence[i])
            {
            options.push(<tr><td><strong className="lang">Licence Type: </strong><span className="lang">{licence[i]}</span></td><td><strong className="lang">Licence File: </strong><span className="lang"><img src={"/uploads/"+licence1[i]} width="200"/></span></td></tr>);
          }}

          return options;
        })()}
        </table>
        {/* <div className='reset-password1'><Link className='btn btn-primary' to='/update-professional-licence'>Update Licence Information</Link></div> */}
      </div>

      
  </div>
        
    </div>
      </div>
      </div>
      </div>
      </div>
      </div>
      
    );
  }
}


export default ViewProfessionalProfile;